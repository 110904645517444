<template>
  <div style="background-color: #E0ECFF; padding: 110px 120px 109px 120px;">
    <v-container>

        <v-container>
          <div>
            <h2 class="mon-bold" style="font-size: min(max(35px, 5vw), 70px); color: #193D6E; margin-bottom: 45px; text-align: center;">
              {{ "¿Por qué FRESHER?" }}
            </h2>
            <h4 class="mon" style="font-size: 30px; font-weight: 400; line-height: 120%; color: #2D2D2D; text-align: center;">{{ "Lorem ipsum dolor sit amet consectetur. Faucibus lobortis pellentesque neque nullam non est. Nibh sit orci magna neque consectetur egestas turpis erat interdum." }}</h4>
          </div>
        </v-container>

        <div class="cards-container">

          <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Shield.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                Seguro
              </v-card-title>
              <v-card-subtitle class="card-subtitle">
                Lorem ipsum dolor sit amet consectetur. A diam vestibulum velit senectus proin condimentum tortor. In id dictum pulvinar sed et semper tortor faucibus. Bibendum porttitor mi quam id arcu.
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Right-Arrow.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                Seguro
              </v-card-title>
              <v-card-subtitle class="card-subtitle">
                Lorem ipsum dolor sit amet consectetur. A diam vestibulum velit senectus proin condimentum tortor. In id dictum pulvinar sed et semper tortor faucibus. Bibendum porttitor mi quam id arcu.
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Bar-Graph.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                Seguro
              </v-card-title>
              <v-card-subtitle class="card-subtitle">
                Lorem ipsum dolor sit amet consectetur. A diam vestibulum velit senectus proin condimentum tortor. In id dictum pulvinar sed et semper tortor faucibus. Bibendum porttitor mi quam id arcu.
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

        <v-card class="custom-card">
          <div class="card-content">
            <div class="icon-container">
              <v-img src="@/assets/icons/Dollar-Coin.svg" class="card-icon"></v-img>
            </div>
            <div>
              <v-card-title class="card-title">
                Seguro
              </v-card-title>
              <v-card-subtitle class="card-subtitle">
                Lorem ipsum dolor sit amet consectetur. A diam vestibulum velit senectus proin condimentum tortor. In id dictum pulvinar sed et semper tortor faucibus. Bibendum porttitor mi quam id arcu.
              </v-card-subtitle>
            </div>
          </div>
        </v-card>

        </div>
    </v-container>

  </div>
</template>

<script>

export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeFeaturedSupplierSkeletonLayout",
  data() {
    return {
      imgPlatform: require('@/assets/images/Grupo26851.png'),
      isHovered: false,
      isHoveredInsurance: false,
      isHoveredLogistic: false,
      isHoveredFinance: false,
    };
  },
};
</script>

<style scoped>

.title {
  font-size: min(max(35px, 5vw), 70px);
  color: #193D6E;
  margin-bottom: 45px;
  text-align: center;
}

.subtitle {
  font-size: 24px;
  font-weight: 200;
  line-height: 120%;
  color: #2D2D2D;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  gap: 2.5%;
  margin-top: 60px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-icon {
  width: 50px;
  height: 50px;
}

.icon-container {
  background-color: #E0ECFF;
  border-radius: 20%; 
  padding: 5%;
  margin-bottom: 10px;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: #193D6E;
  margin-bottom: 15px;
}

.card-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #2D2D2D;
  line-height: 24px;
  text-align: justify;
}

.custom-card {
  width: 394px;
  height: 400px;
  background: #F4F4F4;
  border-radius: 27px;
  padding: 20px;
  text-align: left; /* Alinea el contenido de texto a la izquierda */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.custom-card:hover {
  transform: scale(1.05);
  background-color: rgba(244, 244, 244, 0.8);
}

/* --------------------- En espera de confirmación de eliminación ------------------------------- */
.custom-card__insurance {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.custom-card__logistic {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.custom-card__finance {
  width: 394px;
  height: 432px;
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  background-image: none; /* Inicialmente, no se muestra ninguna imagen de fondo */
}
.hovered-text {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__logistic {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__finance {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__insurance {
  color: white !important;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
  padding-top: 80px;
  transition: padding-top 0.3s; /* Agregamos una transición suave al padding-top */
}
.hovered-text__sub {
  color: white !important;
  font-size: 32px;
  font-weight: 500;
}
.not-hovered-text {
  font-size: 22px; 
  font-weight: 500; 
  padding: 30px;
}
.not-hovered-text__sub {
  font-size: 22px; 
  font-weight: 500;
}

/* custom-card:hover { */
  /* background-image: url('../../../assets/images/Grupo 26860.png'); Ruta de la imagen a mostrar en hover */
  /* background-color: transparent; Establece el fondo transparente */
  /* transform: scale(1.05); */
/* } */

.custom-card__insurance:hover {
  background-image: url('../../../assets/images/Grupo 26859.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}

.custom-card__logistic:hover {
  background-image: url('../../../assets/images/Grupo 26858.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}

.custom-card__finance:hover {
  background-image: url('../../../assets/images/Grupo 26857.png'); /* Ruta de la imagen a mostrar en hover */
  background-color: transparent; /* Establece el fondo transparente */
  transform: scale(1.05);
}
</style>